<template>
  <div v-if="$store.getters['language/getCurrentLocale'] == 'en'" >
    <div class="page-header title-area">
      <div class="header-title_en">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <h1 class="page-title" style="width: 50%;margin: auto !important;padding: 20px 10px; background: red">About Us</h1></div>
          </div>
        </div>
      </div>
      <div class="breadcrumb-area">
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-sm-12 col-xs-12 site-breadcrumb">
              <nav class="breadcrumb">
                <router-link class="home" to="/"><span>{{ getTranslation('home') }}</span></router-link>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
                <span>{{ getTranslation('About us') }}</span>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="aboutsec-2 secpaddbig">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="abotimglft">
              <img v-lazy="main" class="img-responsive">
            </div>
          </div>
          <div class="col-md-12 col-sm-12">
            <div class="abotimglft">
              <img v-lazy="desc" class="img-responsive">
            </div>
          </div>
          <div class="col-md-12 col-sm-12">
            <div class="abotimglft">
              <img v-lazy="slogan" class="img-responsive">
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div v-else >
    <div class="page-header title-area">
      <div class="header-title">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <h1 class="page-title" style="width: 50%;margin: auto !important;padding: 20px 10px; background: red">{{getTranslation('About us')}}</h1></div>
          </div>
        </div>
      </div>
      <div class="breadcrumb-area">
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-sm-12 col-xs-12 site-breadcrumb">
              <nav class="breadcrumb">
                <router-link class="home" to="/"><span>{{ getTranslation('home') }}</span></router-link>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
                <span>{{ getTranslation('About us') }}</span>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="aboutsec-2 secpaddbig">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="abotimglft">
              <img v-lazy="ru.main" class="img-responsive">
            </div>
          </div>
          <div class="col-md-12 col-sm-12">
            <div class="abotimglft">
              <img v-lazy="ru.desc" class="img-responsive">
            </div>
          </div>
          <div class="col-md-12 col-sm-12">
            <div class="abotimglft">
              <img v-lazy="ru.slogan" class="img-responsive">
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "About-Us",
  data() {
    return {
      main: '/images/aboutUs/02 eng.png',
      desc: '/images/aboutUs/03 eng.png',
      slogan: '/images/aboutUs/05 eng.png',
      ru:{
        main: '/images/aboutUs/ru/02.png',
        desc: '/images/aboutUs/ru/03.png',
        slogan: '/images/aboutUs/ru/05.png',
      }
    }
  }
}
</script>

<style scoped>

</style>